export function getPostsQuery(count = 3, showFeaturedPost = false, activeCategory = false, end = '', excludeIds = [], searchQuery = '', showTotalCount = false) {
  let whereFilter = 'status: PUBLISH';

  if (activeCategory) {
    whereFilter += `, categoryId: ${activeCategory}`;
  }

  if (excludeIds.length > 0) {
    whereFilter += `, notIn: "${excludeIds.join(", ")}"`;
  }

  if (searchQuery.length > 0) {
    whereFilter += `, search: "${searchQuery}"`;
  }

  let endCursorFilter = '';
  if (end) {
    endCursorFilter = `, after: "${end}"`;
  }

  // Increase count for featured post
  if (showFeaturedPost) {
    count++;
  }

  return gql`
    query getPosts {
      posts(first: ${count}, where: {${whereFilter}} ${endCursorFilter}) {
        pageInfo {
          ${showTotalCount ? 'total' : ''} 
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            title
            content
            excerpt
            date
            slug
            uri
            featuredImage {
              node {
                altText
                sourceUrl(size: CARD_XL)
                srcSet(size: CARD_XL)
                sizes(size: CARD_XL)
              }
            }
            categories {
              edges {
                node {
                  name
                  uri
                }
              }
            }
          }
        }
      }
    }
  `;
}
