<template>
  <h1 class="text-4xl md:text-5xl mb-8 text-primary font-medium leading-tight" v-html="getFormattedHeadline(headline)"></h1>
</template>

<script setup lang="ts">
  const { getFormattedHeadline } = usePage();
  const props = defineProps(['headline']);
</script>

<style scoped>

</style>
